import AbstractService from "@/app/service/AbstractService.mjs";
import HistoryStore from "@/app/entity/HistoryStore.mjs";

/**
 * 履歴サービス
 */
export default class HistoryService extends AbstractService {

    /** 最大件数 */
    static MAX_COUNT = 9999;

    /** データベース */
    database = null;

    /**
     * 初期化
     */
    async initialize() {
        this.database = this.SERVICES.AppService.getDatabase();
        // ストア初期化
        await this.database.initializeStore(HistoryStore.STORE_NAME, { key: HistoryStore.FLD_ID, autoInc: false }, []);
    }

    /**
     * 履歴追加
     * @param {Object} item データ
     */
    async addHistory(item) {
        await this.database.addItems(HistoryStore.STORE_NAME, [item]);
        await this.refreshHistoryList();
    }

    /**
     * 履歴削除
     * @param {String} id ID
     */
    async deleteHistory(id) {
        await this.database.deleteItems(HistoryStore.STORE_NAME, [id]);
    }

    /**
     * 履歴リスト取得
     * @returns {Array<Object>} 履歴リスト
     */
    async getHistoryList() {
        const items = [];
        await this.database.getItems(HistoryStore.STORE_NAME, function(item) {
            items.push(item);
            return true;
        });
        // 新しい順にソート
        const sortedItems = items.sort(function(a, b) {
            return (a.timestamp < b.timestamp) ? 1 : -1;
        });
        return sortedItems;
    }

    /**
     * 履歴リスト再構築
     */
    async refreshHistoryList() {
        const items = await this.getHistoryList();
        // 古い順にソート
        const sortedItems = items.sort(function(a, b) {
            return (a.timestamp < b.timestamp) ? -1 : 1;
        });
        // 古いレコード削除
        const ids = [];
        if (sortedItems.length > HistoryService.MAX_COUNT) {
            for (let i = 0; i < (sortedItems.length - HistoryService.MAX_COUNT); i++) {
                const sortedItem = sortedItems[i];
                ids.push(sortedItem.id);
            }
        }
        if (ids.length > 0) {
            await this.database.deleteItems(HistoryStore.STORE_NAME, ids);
        }
    }

    /**
     * 履歴リストクリア
     */
    async clearHistoryList() {
        await this.database.truncateItems(HistoryStore.STORE_NAME);
    }
};
