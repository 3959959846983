<script>
    import Menubar from "primevue/menubar"

    export default {
        extends: Menubar,
        name: 'ex-menubar',
        render: Menubar.render
    };
</script>

<style scoped>
</style>
