<template>
    <Dialog modal v-bind:visible="visible" header="お問合せ" :style="{ width: '75vw' }" :breakpoints="{ '960px': '75vw', '641px': '100vw' }"
        v-on:update:visible="onVisible" :draggable="draggable" >
        <div id="top" class="mx-auto w-full max-w-screen-xl">

            <div class="my-12 px-4 my-4">
                ※<span class="font-bold"> 電子メール(e-mail) </span>でのお問い合わせとなります。<br>
                <br>
                以下の点にご留意の上、お問い合わせください。<br>
            </div>

            <div class="my-12 p-4 border-2" v-html="contactNoticeHtml">
            </div>

            <div class="pb-6 px-4 my-4">
                お問い合わせ前に、上記の注意事項をよくお読みいただき、ご理解の上でお問い合わせください。<br>
                <br>
                <br>
                連絡先：<a ref="sendLink" :href="'mailto:'+email" class="font-bold hover:underline">{{email}}</a><br>
            </div>
            <div class="card flex justify-content-end gap-2 py-2 px-2">
                <Button type="button" label="メーラー起動" icon="pi pi-send" @click="onSend" severity="secondary" outlined/>
            </div>

        </div>
    </Dialog>
</template>

<script>
    import Dialog from 'primevue/dialog';
    import Button from 'primevue/button';

    import { marked } from 'marked';

    export default {
        components: {
            Dialog,
            Button
        },
        props: {
            visible: {
                type: Boolean,
                default: false
            },
            draggable: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                email: this.$Settings.contact.email,
                contactNoticeHtml: null
            }
        },
        mounted() {
            const me = this
            fetch('./assets/portal/contact.md')
                .then(response => response.text())
                .then(data => {
                    me.contactNoticeHtml = marked.parse(data);
                });
        },
        emits: ['change-visible'],
        methods: {
            /**
             * "表示切替"イベント時処理
             */
            onVisible(visible) {
                this['$emit']('change-visible', visible);
            },

            /**
             * "送信"イベント時処理
             */
            onSend() {
                this.$refs.sendLink.click();
            }
        }
    };
</script>

<style scoped>
</style>