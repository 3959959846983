<template>
    <div class="card justify-content-center">
        <div class="card flex flex-wrap justify-content-center">
            <Button label="HOME" severity="secondary" text @click="onHome" />
            <Button label="当サイトについて" severity="secondary" text @click="onApplicationWindowVisible(true)" />
            <Button label="利用規約" severity="secondary" text @click="onTermsWindowVisible(true)" />
            <Button label="お問合せ" severity="secondary" text @click="onContactWindowVisible(true)" />
            <Button label="管理人" severity="secondary" text @click="onProfileWindowVisible(true)" />
        </div>
        <div class="card flex justify-content-center">
            <p class="py-2 text-secondary">{{ copyright }}</p>
        </div>
    </div>

    <UxApplicationWindow :visible="applicationWindowVisible" v-on:change-visible="onApplicationWindowVisible" />
    <UxTermsWindow :visible="termsWindowVisible" v-on:change-visible="onTermsWindowVisible" />
    <UxProfileWindow :visible="profileWindowVisible" v-on:change-visible="onProfileWindowVisible" />
    <UxContactWindow :visible="contactWindowVisible" v-on:change-visible="onContactWindowVisible" />
</template>

<script>
    import UxApplicationWindow from "@/ux/window/UxApplicationWindow";
    import UxTermsWindow from "@/ux/window/UxTermsWindow";
    import UxProfileWindow from "@/ux/window/UxProfileWindow";
    import UxContactWindow from "@/ux/window/UxContactWindow";

    import Button from 'primevue/button';

    import HtmlUtils from "@/app/util/html-utils.mjs";

    export default {
        components: {
            Button,
            UxApplicationWindow,
            UxTermsWindow,
            UxProfileWindow,
            UxContactWindow,
        },
        props: {
        },
        data() {
            return {
                applicationWindowVisible: false,
                termsWindowVisible: false,
                profileWindowVisible: false,
                contactWindowVisible: false,
            };
        },
        computed: {
            copyright() {
                return this.$Settings.copyright;
            }
        },
        emits: [],
        methods: {

            /**
             * "HOME"イベント時処理
             */
            onHome() {
                HtmlUtils.movePage(`./`, '_self');
            },

            /**
             * "利用規約ウィンドウ表示切替"イベント時処理
             */
            onTermsWindowVisible(visible) {
                this.termsWindowVisible = visible;
            },

            /**
             * "プロフィールウィンドウ表示切替"イベント時処理
             */
            onProfileWindowVisible(visible) {
                this.profileWindowVisible = visible;
            },

            /**
             * "お問合せウィンドウ表示切替"イベント時処理
             */
            onContactWindowVisible(visible) {
                this.contactWindowVisible = visible;
            },

            /**
             * "アプリケーションウィンドウ表示切替"イベント時処理
             */
            onApplicationWindowVisible(visible) {
                this.applicationWindowVisible = visible;
            }
        }
    };
</script>

<style scoped>
</style>