/**
 * 抽象サービス
 */
export default class AbstractService {

    /** サービス */
    SERVICES = {};

    /**
     * 初期化
     */
    async initialize() {
    }
};
