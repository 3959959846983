<template>
    <img alt="logo" :src="imageUrl" :height="height" :width="width" />
</template>

<script>
    export default {
        components: {
        },
        props: {
            imageUrl: {
                type: String,
                default: "https://primefaces.org/cdn/primevue/images/logo.svg"
            },
            height: {
                type: String,
                default: null
            },
            width: {
                type: String,
                default: null
            }
        }
    };
</script>

<style scoped>
</style>
