<template>
    <div class="navigation-panel card flex justify-content-center">
        <ExNavigation v-bind:visible="visible" position="left">
            <template #header>
                <slot name="header"></slot>
            </template>
            <template #default>
                <PanelMenu v-bind:expandedKeys="expandedKeys" :model="navigationItems" class="w-full" />
            </template>
        </ExNavigation>
    </div>
</template>

<script>
    import ExNavigation from "@/overrides/frame/ExNavigation"
    import PanelMenu from 'primevue/panelmenu'

    export default {
        components: {
            ExNavigation,
            PanelMenu
        },
        props: {
            visible: {
                type: Boolean,
                default: false
            },
            navigationItems: {
                type: Array
            },
            expandedKeys: {
                type: Object
            }
        },
        data() {
            return {
            };
        }
    };
</script>

<style scoped>
    .navigation-panel {
        display: visible;
    }
</style>
