import AbstractService from "@/app/service/AbstractService.mjs";
import IndexedDB from "@/app/core/IndexedDB.mjs";

/**
 * アプリケーションサービス
 */
export default class AppService extends AbstractService {

    /**
     * テーマ
     */
    /** saga-blue */
    static TC_SAGA_BLUE = 'saga-blue';
    /** vela-green */
    static TC_VELA_GREEN = 'vela-green';

    /** データベース */
    database = new IndexedDB('dayseize_1_0_0');

    /**
     * テーマ変更
     * @param {String} themeCode テーマコード
     */
    changeTheme(themeCode) {
        const themeLink = document.getElementById('theme-link');
        themeLink.href = `/themes/${themeCode}/theme.css`;
    }

    /**
     * テーマデータ取得
     * @returns {Array<Object>} テーマデータ
     */
    getThemeDatas() {
        return [
            { code: AppService.TC_SAGA_BLUE, color: '#EEE', name: '標準' },
            { code: AppService.TC_VELA_GREEN, color: '#233B6C', name: 'ダーク' }
        ];
    }

    /**
     * データベース取得
     * @returns {IndexedDB} データベース
     */
    getDatabase() {
        return this.database;
    }

};
