import AbstractService from "@/app/service/AbstractService.mjs";

/**
 * 問題サービス
 */
export default class QuestionService extends AbstractService{

    /** ブックキャッシュ */
    bookCaches = null;
    /** ジャンルキャッシュ */
    genreCaches = {};
    /** 問題キャッシュ */
    questionCaches = {};

    /**
     * ブックカテゴリーツリーリスト取得
     * @returns {Array<Object>} ブックカテゴリーツリーリスト
     */
    async getBookCategoryTreeList() {
        const me = this
        try {
            // ブックカテゴリー一覧取得
            const response = await fetch('./data/books-category.json');
            // エラー判定
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const bookCategoryList = await response.json();
            const bookList = await me.getBookList({});

            for (const bookCategory of bookCategoryList) {
                bookCategory.items = bookCategory.items||[];
                for (const book of bookList) {
                    if (bookCategory.code == book.category) {
                        bookCategory.items.push(book);
                    }
                }
            }
            return bookCategoryList;
        } catch (error) {
            console.error('Fetch error:', error);
            throw error;
        }
    }

    /**
     * ブックリスト取得
     * @param {Object} filter 抽出条件
     * @returns {Array<Object>} ブックリスト
     */
    async getBookList(filter={}) {
        const me = this
        try {
            // ブック一覧取得
            let bookList = null;
            if (this.bookCaches) {
                bookList = this.bookCaches;
            } else {
                const response = await fetch('./data/books.json');
                // エラー判定
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                bookList = await response.json();
                this.bookCaches = bookList;
            }

            // データ取得
            const dataList = [];
            for (let i = 0; i < bookList.length; i++) {
                const book = bookList[i];
                if (book.hidden) {
                    continue;
                }
                if (filter.codes && filter.codes.length > 0) {
                    if (filter.codes.includes(book.code)) {
                        dataList.push(book);
                    }
                    continue;
                }
                dataList.push(book);
            }
            return dataList;
        } catch (error) {
            console.error('Fetch error:', error);
            throw error;
        }
    }

    /**
     * ブック取得
     * @param {String} code コード
     * @returns {Object} ブック
     */
    async getBook(code) {
        const me = this
        const bookList = await me.getBookList({ codes: [code] });
        return bookList.length > 0 ? bookList[0] : null;
    }

    /**
     * ジャンルカテゴリーツリーリスト取得
     * @param {String} bookCode ブックコード
     * @returns {Array<Object>} ジャンルカテゴリーツリーリスト
     */
    async getGenreCategoryTreeList(bookCode) {
        const me = this
        try {
            // ジャンルカテゴリー一覧取得
            const response = await fetch(`./data/genre/${bookCode}/${bookCode}-category.json`);
            // エラー判定
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const genreCategoryList = await response.json();
            const genreList = await me.getGenreList({ books: [bookCode] });

            for (const genreCategory of genreCategoryList) {
                genreCategory.items = genreCategory.items||[];
                for (const genre of genreList) {
                    if (genreCategory.code == genre.category) {
                        genreCategory.items.push(genre);
                    }
                }
            }
            return genreCategoryList;
        } catch (error) {
            // console.error('Fetch error:', error);
            // throw error;
            return [{
                code: null,
                name: null,
                items: await me.getGenreList({ books: [bookCode] })
            }]
        }
    }

    /**
     * ジャンルリスト取得
     * @param {Object} filter 抽出条件
     * @returns {Array<Object>} ジャンルリスト
     */
    async getGenreList(filter={}) {
        const me = this
        try {
            const dataList = [];
            const bookList = await this.getBookList({ codes: filter.books });
            for (const book of bookList) {
                // ジャンル一覧取得
                let genreList = null;
                if (this.genreCaches[book.code]) {
                    genreList = this.genreCaches[book.code];
                } else {
                    const response = await fetch(`./data/genre/${book.code}/${book.code}.json`);
                    // エラー判定
                    if (!response.ok) {
                        throw new Error(`HTTP error! Status: ${response.status}`);
                    }
                    genreList = await response.json();
                    this.genreCaches[book.code] = genreList;
                }

                // データ取得
                for (let i = 0; i < genreList.length; i++) {
                    const genre = genreList[i];
                    if (genre.hidden) {
                        continue;
                    }
                    if (filter.codes && filter.codes.length > 0) {
                        if (filter.codes.includes(genre.code)) {
                            dataList.push(genre);
                        }
                        continue;
                    }
                    dataList.push(genre);
                }
            }
            return dataList;
        } catch (error) {
            console.error('Fetch error:', error);
            throw error;
        }
    }

    /**
     * ジャンル取得
     * @param {String} code コード
     * @returns {Object} ジャンル
     */
    async getGenre(code) {
        const me = this
        const genreList = await me.getGenreList({ codes: [code] });
        return genreList.length > 0 ? genreList[0] : null;
    }

    /**
     * 問題リスト取得
     * @param {Object} filter 抽出条件
     * @returns {Array<Object>} 問題リスト
     */
    async getQuestionList(filter={}) {
        const me = this;
        const start = filter.start ? (filter.start < 0 ? 0 : filter.start) : 0;
        const limit = filter.limit ? filter.limit : -1;
        try {
            const dataList = [];
            const genreList = await this.getGenreList({ books: filter.books, codes: filter.genres });
            for (const genre of genreList) {
                // 問題一覧取得
                let questionList = null;
                if (this.questionCaches[genre.code]) {
                    questionList = this.questionCaches[genre.code];
                } else {
                    const response = await fetch(`./data/question/${genre.code}/${genre.code}.json`);
                    // エラー判定
                    if (!response.ok) {
                        throw new Error(`HTTP error! Status: ${response.status}`);
                    }
                    questionList = await response.json();
                    this.questionCaches[genre.code] = questionList;
                }

                // データ取得
                for (let i = 0; i < questionList.length; i++) {
                    const question = questionList[i];
                    if (filter.ids && filter.ids.length > 0) {
                        if (filter.ids.includes(question.id)) {
                            dataList.push(question);
                        }
                        continue;
                    }
                    dataList.push(question);

                    // 制限値判定
                    if (limit >= 0) {
                        if (start + limit <= dataList.length) {
                            break;
                        }
                    }
                }

                // 制限値判定
                if (limit >= 0) {
                    if (start + limit <= dataList.length) {
                        break;
                    }
                }
            }
            return limit >= 0 ? dataList.slice(start, (start + limit < dataList.length) ? start + limit : dataList.length) : dataList;
        } catch (error) {
            console.error('Fetch error:', error);
            throw error;
        }
    }

    /**
     * 問題取得
     * @param {String} genre ジャンル
     * @param {String} id ID
     * @returns {Object} 問題
     */
    async getQuestion(genre, id) {
        const me = this
        const questionList = await me.getQuestionList({ genres: [genre] });
        for (const question of questionList) {
            if (question.id == id) {
                return question;
            }
        }
        return null;
    }

    /**
     * 前の問題取得
     * @param {String} genre ジャンル
     * @param {String} id ID
     * @returns {Object} 問題
     */
    async getPrevQuestion(genre, id) {
        const me = this
        const questionList = await me.getQuestionList({ genres: [genre] });
        for (let i = 0; i < questionList.length; i++) {
            const question = questionList[i];
            if (question.id == id) {
                return i == 0 ? null : questionList[i - 1];
            }
        }
        return null;
    }

    /**
     * 次の問題取得
     * @param {String} genre ジャンル
     * @param {String} id ID
     * @returns {Object} 問題
     */
    async getNextQuestion(genre, id) {
        const me = this
        const questionList = await me.getQuestionList({ genres: [genre] });
        for (let i = 0; i < questionList.length; i++) {
            const question = questionList[i];
            if (question.id == id) {
                return i == questionList.length - 1 ? null : questionList[i + 1];
            }
        }
        return null;
    }

    /**
     * ランダム問題取得
     * @param {Object} filter 抽出条件
     * @returns {Object} ランダム問題
     */
    async getRandomQuestion(filter={}) {
        const me = this
        const questionList = await me.getQuestionList(filter);
        const min = Math.ceil(0);
        const max = Math.floor(questionList.length - 1);
        const randomNum = Math.floor(Math.random() * (max - min + 1)) + min;
        return questionList[randomNum];
    }

};
