import AbstractService from "@/app/service/AbstractService.mjs";
import FavoriteStore from "@/app/entity/FavoriteStore.mjs";

/**
 * お気に入りサービス
 */
export default class FavoriteService extends AbstractService {

    /** 最大件数 */
    static MAX_OUNT = 9999;

    /** データベース */
    database = null;

    /**
     * 初期化
     */
    async initialize() {
        this.database = this.SERVICES.AppService.getDatabase();
        // ストア初期化
        await this.database.initializeStore(FavoriteStore.STORE_NAME, { key: FavoriteStore.FLD_ID, autoInc: false }, []);
    }

    /**
     * お気に入り追加
     * @param {Object} item データ
     */
    async addFavorite(item) {
        const count = await this.database.getItemCount(FavoriteStore.STORE_NAME);
        if (count >= FavoriteService.MAX_OUNT) {
            return;
        }
        await this.database.addItems(FavoriteStore.STORE_NAME, [item]);
    }

    /**
     * お気に入り削除
     * @param {String} id ID
     */
    async deleteFavorite(id) {
        await this.database.deleteItems(FavoriteStore.STORE_NAME, [id]);
    }

    /**
     * お気に入り取得
     * @param {String} id ID
     * @returns {Object} お気に入り
     */
    async getFavorite(id) {
        const item = await this.database.getItem(FavoriteStore.STORE_NAME, id);
        return item;
    }

    /**
     * お気に入りリスト取得
     * @returns {Array<Object>} お気に入りリスト
     */
    async getFavoriteList() {
        const items = [];
        await this.database.getItems(FavoriteStore.STORE_NAME, function(item) {
            items.push(item);
            return true;
        });
        // 新しい順にソート
        const sortedItems = items.sort(function(a, b) {
            return (a.timestamp < b.timestamp) ? 1 : -1;
        });
        return sortedItems;
    }

    /**
     * お気に入りリストクリア
     */
    async clearFavoriteList() {
        await this.database.truncateItems(FavoriteStore.STORE_NAME);
    }
};
