<template>
    <!-- メニューバー -->
    <MainMenubar
        v-on:logo="onLogo"
        v-on:navigation="onNavigation"
        v-on:changemode="onChangeMenuOption"
        ref="menuPanel"/>

    <!-- ナビゲーション -->
    <MainNavigation
        :visible="navigationVisible"
        v-on:close="onCloseNavigation"
        v-on:select-item="onSelectNavigationItem"
        ref="navigationPanel"/>

    <!-- メインパネル -->
    <MainMainPanel
        :page="page"
        :book="book"
        :genre="genre"
        :start="start"
        :question="question"
        :answer="answer"
        ref="mainPanel"/>

    <router-view />
</template>

<script>
    import MainMenubar from "@/views/main/MainMenubar";
    import MainNavigation from "@/views/main/MainNavigation";
    import MainMainPanel from "@/views/main/MainMainPanel";

    import HtmlUtils from "@/app/util/html-utils.mjs";

    export default {
        components: {
            MainMenubar,
            MainNavigation,
            MainMainPanel
        },
        data() {
            return {
                navigationVisible: false,
                page: null,
                book: null,
                genre: null,
                start: 0,
                question: null,
                answer: null
            };
        },
        beforeCreate() {
        },
        created() {
        },
        beforeMount() {
        },
        mounted() {
            // リクエストパラメータ処理
            this.page = HtmlUtils.getRequestParameter('page');
            this.book = HtmlUtils.getRequestParameter('book');
            this.genre = HtmlUtils.getRequestParameter('genre');
            this.start = Number(HtmlUtils.getRequestParameter('start')||0);
            this.question = HtmlUtils.getRequestParameter('question');
            this.answer = HtmlUtils.getRequestParameter('answer');
        },
        beforeUpdate() {
        },
        updated() {
        },
        beforeUnmount() {
        },
        unmounted() {
        },
        watch: {
        },
        computed: {
        },
        methods: {
            /**
             * "ロゴ"ボタン押下時処理
             */
            onLogo() {
                this.$refs.menuPanel.page = null;
                this.page = null;
                this.book = null;
                this.genre = null;
                this.start = 0;
                this.question = null;
                this.answer = null;
                HtmlUtils.movePage( `./`, '_self');
            },

            /**
             * "ナビゲーション"イベント時処理
             */
            onNavigation: function() {
                this.navigationVisible = !this.navigationVisible;
            },

            /**
             * "ナビゲーション閉じる"イベント時処理
             */
            onCloseNavigation: function() {
                this.navigationVisible = false;
            },

            /**
             * "ナビゲーション項目選択"イベント時処理
             */
            onSelectNavigationItem: function(itemId) {
                switch (itemId) {
                    default:
                        break;
                }
            },

            /**
             * "メニューオプション変更"イベント時処理
             */
            onChangeMenuOption(mode) {
                switch (mode) {
                    case 'history':
                        HtmlUtils.movePage(`./?page=history`, '_self');
                        break;
                    case 'favorite':
                        HtmlUtils.movePage(`./?page=favorite`, '_self');
                        break;
                    default:
                        HtmlUtils.movePage('./', '_self');
                        break;
                }
            }
        }
    };
</script>

<style scoped>
</style>