<template>
    <UxMenubar :menuItems="menuItems" :visible="true">
        <template #start>
            <div class="card flex justify-content-center gap-2">
                <!-- <Button v-tooltip.bottom="'メニュー'" icon="pi pi-bars" @click="onNavigation" severity="secondary" outlined/> -->
                <UxLogo :src="'/assets/image/logo.png'" :height="'30'" class="cursor-pointer mt-1" @click="onLogo" />
            </div>
        </template>
        <template #end>
            <div class="card flex justify-content-center gap-2">
                <MqResponsive target="xs-">
                    <div class="card flex align-items-center justify-content-center gap-2">
                        <div v-for="(menuModeOption) of menuModeOptionsForSmall" :key="menuModeOption.value">
                            <Button type="button"  v-tooltip.bottom="menuModeOption.name" :icon="menuModeOption.icon" @click="onChangeMenuOption(menuModeOption.value)" severity="secondary" outlined />
                        </div>
                    </div>
                </MqResponsive>
                <MqResponsive target="sm+">
                    <div class="card flex align-items-center justify-content-center gap-2">
                        <div v-for="(menuModeOption) of menuModeOptions" :key="menuModeOption.value">
                            <Button type="button" :label="menuModeOption.name" :icon="menuModeOption.icon" @click="onChangeMenuOption(menuModeOption.value)" severity="secondary" outlined />
                        </div>
                    </div>
                </MqResponsive>
            </div>
        </template>
    </UxMenubar>
</template>

<script>
    import { MqResponsive } from "vue3-mq";
    import Button from 'primevue/button';

    import UxMenubar from "@/ux/frame/UxMenubar"
    import UxLogo from "@/ux/field/UxLogo"

    export default {
        components: {
            MqResponsive,
            Button,
            UxMenubar,
            UxLogo
        },
        props: {
        },
        emits: ['logo','navigation','changemode'],
        data() {
            return {
                menuModeOptions: [
                    { name: '付箋', icon: 'pi pi-star-fill', value: 'favorite' },
                    { name: '履歴', icon: 'pi pi-bookmark-fill', value: 'history' },
                    // { name: '利用規約', icon: 'pi pi-file', value: 'terms' },
                    // { name: 'お問合せ', icon: 'pi pi-envelope', value: 'contact' },
                    // { name: '管理人', icon: 'pi pi-user', value: 'profile' },
                ],
                menuModeOptionsForSmall: [
                    { name: '付箋', icon: 'pi pi-star-fill', value: 'favorite' },
                    { name: '履歴', icon: 'pi pi-bookmark-fill', value: 'history' },
                    // { name: '利用規約', icon: 'pi pi-file', value: 'terms' },
                    // { name: 'お問合せ', icon: 'pi pi-envelope', value: 'contact' },
                    // { name: '管理人', icon: 'pi pi-user', value: 'profile' },
                ],
                menuItems: []
            };
        },
        mounted() {
        },
        computed: {
        },
        methods: {
            /**
             * "ロゴ"ボタン押下時処理
             */
            onLogo() {
                this['$emit']('logo');
            },

            /**
             * "ナビゲーション"ボタン押下時処理
             */
            onNavigation() {
                this['$emit']('navigation');
            },

            /**
             * "メニューオプション変更"イベント時処理
             */
            onChangeMenuOption(page) {
                this['$emit']('changemode', page);
            }
        }
    };
</script>

<style scoped>
</style>
