<template>
    <div class="card">
        <div class="mb-5 pb-5">
            <UxSubjectField :title="'付箋'" :desc="''"/>

            <div class="flex flex-wrap justify-content-start my-4">
                <div class="px-2 text-secondary">
                    ※付箋情報はブラウザ内に保存されています。別端末では参照いただけませんので、ご注意ください。
                </div>
                <div class="px-2 text-secondary">
                    ※また「シークレットモード」を使用されている場合は、ご利用できない場合がございますのでご了承ください。
                </div>
            </div>

            <div class="card flex justify-content-between my-5">
                <div class="card flex justify-content-start gap-2 py-2 px-2">
                    <Button type="button" label="クリア" icon="pi pi-pencil" @click="onClear" severity="secondary" outlined />
                </div>
            </div>

            <div class="my-5">
                <div v-for="(favoriteInfo) of favoriteInfos" :key="favoriteInfo.id">
                    <div class="flex-grow-1 flex align-items-center justify-content-start bg-surface-0">
                        <div class="favorite-item w-full py-2">
                            <div class="flex flex-wrap">
                                <p class="text-lg text-overflow-ellipsis mx-3">問. <span v-html="favoriteInfo.title"></span></p>
                            </div>
                            <div class="flex flex-wrap px-5">
                                <div class="card flex justify-content-end gap-2 py-2 px-2">
                                    <Button type="button" label="問題へ" @click="onClickItem(favoriteInfo)" severity="secondary" outlined />
                                    <Button type="button" label="削除" @click="onDeleteItem(favoriteInfo)" severity="secondary" outlined />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="favorite-item w-full"></div>
            </div>
        </div>

        <UxSeparator class="my-5"/>

        <div class="my-5 py-5">
            <UxFooterPanel/>
        </div>
    </div>
</template>

<script>
    import UxSeparator from '@/ux/field/UxSeparator';
    import UxSubjectField from '@/ux/field/UxSubjectField';
    import UxFooterPanel from '@/ux/panel/UxFooterPanel';
    import Button from 'primevue/button';

    import HtmlUtils from "@/app/util/html-utils.mjs";

    export default {
        components: {
            UxSeparator,
            UxSubjectField,
            UxFooterPanel,
            Button
        },
        props: {
        },
        data() {
            return {
                favoriteInfos: []
            };
        },
        mounted() {
            const me = this;
            // 付箋リスト読込
            this.$Service.FavoriteService.getFavoriteList().then(function(result) {
                me.favoriteInfos = result;
            });
        },
        unmounted() {
        },
        watch: {
        },
        emits: [],
        methods: {
            /**
             * "クリア"イベント時処理
             */
            onClear() {
                const me = this;
                this.$Service.FavoriteService.clearFavoriteList().then(function() {
                    me.favoriteInfos = [];
                });
            },

            /**
             * "メニュー選択"イベント時処理
             */
            onClickItem(item) {
                HtmlUtils.movePage(`./?page=question&book=${item.book}&genre=${item.genre}&question=${item.id}`, '_self');
            },

            /**
             * "メニュー削除"イベント時処理
             */
            onDeleteItem(item) {
                const me = this;
                me.$Service.FavoriteService.deleteFavorite(item.id).then(function() {
                    // 付箋リスト読込
                    me.$Service.FavoriteService.getFavoriteList().then(function(result) {
                        me.favoriteInfos = result;
                    });
                });
            }
        }
    };
</script>

<style scoped>
.favorite-item {
    border-left: 8px solid var(--orange-500);
    border-right:  1px solid #ccc;
    border-top:  1px solid #ccc;
    border-bottom: none;
}
</style>