<template>
    <div class="card">

        <UxSubjectField :title="bookInfo ? bookInfo.name : ''" :desc="''"/>

        <div class="flex align-items-start justify-content-between overflow-hidden w-full max-h-20rem my-4">
            <img :src="bookInfo ? `/data/genre/${bookInfo.code}/${bookInfo.image}` : ''" class="w-full" alt="画像なし">
        </div>

        <div class="card flex justify-content-between my-5">
            <div class="card flex justify-content-start gap-2 py-2 px-2">
                <Button type="button" label="戻る" icon="pi pi-arrow-left" @click="onBack" severity="secondary" outlined />
            </div>
        </div>

        <UxSeparator class="my-5"/>

        <div class="mx-5 my-4 text-left">
            <div class="genre-desc">
                <span class="genre-desc-circle1">せ</span><span class="genre-desc-circle2">め</span>
                <p v-html="(bookInfo||{}).desc||''"></p>
            </div>
        </div>

        <UxSeparator class="my-5"/>

        <div class="my-5 py-5">
            <div v-for="(genreCategoryInfo) of genreCategoryInfos" :key="genreCategoryInfo.code">

                <UxSubjectField v-if="genreCategoryInfo.code && genreCategoryInfo.items.length > 0" :title="genreCategoryInfo.name" :desc="''"/>

                <div class="flex flex-column my-5">
                    <div class="flex flex-wrap md:flex-wrap">
                        <div v-for="(genreInfo) of genreCategoryInfo.items" :key="genreInfo.code">
                            <div class="flex align-items-center justify-content-center cursor-pointer font-bold"
                                style="min-width: 200px; min-height: 60px" @click="onClickItem(genreInfo.code)">
                                <div class="genre-item-box bg-orange-100 hover:bg-orange-300">
                                    <div class="genre-item"></div>
                                    <div class="flex align-items-center justify-content-center">
                                        <img :src="'/assets/image/genre-menu.png'" alt="画像なし">
                                        <p class="text-xl px-2">{{genreInfo.name}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <UxSeparator class="my-5"/>

        <div class="my-5 py-5">
            <UxFooterPanel/>
        </div>
    </div>
</template>

<script>
    import UxSeparator from '@/ux/field/UxSeparator';
    import UxSubjectField from '@/ux/field/UxSubjectField';
    import UxFooterPanel from '@/ux/panel/UxFooterPanel';
    import Button from 'primevue/button';

    import HtmlUtils from "@/app/util/html-utils.mjs";

    export default {
        components: {
            UxSeparator,
            UxSubjectField,
            UxFooterPanel,
            Button
        },
        props: {
            book: {
                type: String,
                default: null
            }
        },
        data() {
            return {
                bookInfo: null,
                genreCategoryInfos: []
            };
        },
        mounted() {
            const me = this;
            // ブック情報読込
            this.$Service.QuestionService.getBook(this.book).then(function(result) {
                me.bookInfo = result;
            });
            // ジャンルリスト読込
            this.$Service.QuestionService.getGenreCategoryTreeList(this.book).then(function(result) {
                me.genreCategoryInfos = result;
            });
        },
        unmounted() {
        },
        watch: {
        },
        emits: [],
        methods: {
            /**
             * "戻る"イベント時処理
             */
            onBack() {
                HtmlUtils.movePage(`./?page=home&book=${this.book}`, '_self');
            },

            /**
             * "メニュー選択"イベント時処理
             */
            onClickItem(id) {
                HtmlUtils.movePage(id ? `./?page=question-list&book=${this.book}&genre=${id}` : `./?page=question-list&book=${this.book}`, '_self');
            }
        }
    };
</script>

<style scoped>
.genre-item-box{
    margin: 1em 1rem;
    padding: 1em;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.15);
}

.genre-item{
    display: block;
    background-color: #ccb;
    opacity: 0.2;
    transform: rotate(3deg);
    width: 40%;
    height: 20px;
    margin: -1.5em auto 0 auto;
    border-left: 3px dotted rgba(0,0,0,.1);
    border-right: 3px dotted rgba(0,0,0,.1);
}

.genre-item-box p{
    margin: 0;
    padding: 0 0;
    user-select: none;
}


.genre-desc{
    position: relative;
    background: rgba(255, 229, 178, 0.61);
    margin: 2em 0;
    padding: 25px;
    border-radius: 10px;
}

.genre-desc-circle1{
    font-size: 16px;
    line-height: 1.5;
    position: absolute;
    margin-top: -37px;
    font-weight: bold;
    color: #fff;
    background-color:#ffa726;
    border-radius: 50%;
    text-align: center;
    width: 24px;
    height: 24px;
}

.genre-desc-circle1:after{
    content: "つ";
    position: absolute;
    margin-top: .2em;
    font-weight: bold;
    color: #fff;
    background-color:#ffa726;
    border-radius: 50%;
    text-align: center;
    width: 24px;
    height: 24px;
}

.genre-desc-circle2:before{
    content: "";
    position: absolute;
    top: 100%;
    margin-left: -12px;
    border: 5px solid transparent;
    border-top: 10px solid #ffa726;
    -ms-transform: rotate(-20deg);
    -webkit-transform: rotate(-20deg);
    transform: rotate(-20deg);
}

.genre-desc-circle2{
    position: absolute;
    font-size: 16px;
    line-height: 1.5;
    margin-top: -37px;
    margin-left: 40px;
    font-weight: bold;
    color: #fff;
    background-color:#ffa726;
    border-radius: 50%;
    text-align: center;
    width: 24px;
    height: 24px;
}

.genre-desc-circle2:after{
    content: "い";
    position: absolute;
    margin-top: .2em;
    font-weight: bold;
    color: #fff;
    background-color:#ffa726;
    border-radius: 50%;
    text-align: center;
    width: 24px;
    height: 24px;
}

.genre-desc p{
    margin: 0;
    padding: 10px 0px;
}
</style>