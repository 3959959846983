<template>
    <div class="card">
        <div class="flex align-items-center justify-content-start">
            <img :src="'/assets/image/carousel.png'" class="max-w-full" alt="画像なし">
        </div>
        <div class="mx-5 my-4 text-left">
            <p class="text-700">当サイトは各種ジャンルの「一問一答」サービスを提供しています。</p>
            <p class="text-700">勉強や資格などにご活用ください。</p>
        </div>

        <UxSeparator class="my-5"/>

        <div class="my-5 py-5">
            <div v-for="(bookCategoryInfo) of bookCategoryInfos" :key="bookCategoryInfo.code">

                <UxSubjectField v-if="bookCategoryInfo.items.length > 0" :title="bookCategoryInfo.name" :desc="''"/>

                <div class="flex flex-column my-5">
                    <div class="flex flex-wrap md:flex-wrap">
                        <div v-for="(bookInfo) of bookCategoryInfo.items" :key="bookInfo.code">
                            <Card class="m-2 cursor-pointer surface-overlay border-2 border-gray-300 hover:border-orange-500" style="width: 20rem; height: 20rem; overflow: hidden" @click="onClickItem(bookInfo.code)">
                                <template #header>
                                    <div class="flex align-items-center justify-content-between overflow-hidden w-full h-10rem">
                                        <img alt="user header" :src="bookInfo ? `/data/genre/${bookInfo.code}/${bookInfo.image}` : ''" class="w-full" />
                                    </div>
                                </template>
                                <template #title>{{bookInfo.name}}</template>
                                <template #subtitle>
                                    <!-- <div class="flex flex-wrap text-left"> -->
                                        <p class="white-space-normal text-overflow-ellipsis" style="word-break: break-all;" v-html="bookInfo.desc"></p>
                                    <!-- </div> -->
                                </template>
                                <!-- <template #content>
                                    <div class="flex flex-wrap text-left">
                                        <p class="text-overflow-ellipsis" style="word-break: break-all;" v-html="bookInfo.desc"></p>
                                    </div>
                                </template> -->
                                <template #footer>
                                    <div class="flex justify-content-end gap-3 mt-1">
                                        <!-- <Button label="Save" class="w-full" /> -->
                                    </div>
                                </template>
                            </Card>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <UxSeparator class="my-5"/>

        <div class="my-5 py-5">
            <UxFooterPanel/>
        </div>
    </div>
</template>

<script>
    import UxSeparator from '@/ux/field/UxSeparator';
    import UxSubjectField from '@/ux/field/UxSubjectField';
    import UxFooterPanel from '@/ux/panel/UxFooterPanel';
    import Card from 'primevue/card';

    import HtmlUtils from "@/app/util/html-utils.mjs";

    export default {
        components: {
            UxSeparator,
            UxSubjectField,
            UxFooterPanel,
            Card
        },
        props: {
        },
        data() {
            return {
                bookCategoryInfos: []
            };
        },
        mounted() {
            const me = this;
            // ブックリスト読込
            this.$Service.QuestionService.getBookCategoryTreeList({}).then(function(result) {
                me.bookCategoryInfos = result;
            });
        },
        unmounted() {
        },
        watch: {
        },
        emits: [],
        methods: {
            /**
             * "メニュー選択"イベント時処理
             */
            onClickItem(id) {
                HtmlUtils.movePage(id ? `./?page=genre&book=${id}` : `./?page=genre`, '_self');
            }
        }
    };
</script>

<style scoped>
</style>