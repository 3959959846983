/**
 * お気に入りストア
 */
export default class FavoriteStore {

    /** ストア名 */
    static STORE_NAME = 'question_favorite';

    /**
     * 属性
     */
    /** ブック */
    static FLD_BOOK = 'book';
    /** ジャンル */
    static FLD_GENRE = 'genre';
    /** ID */
    static FLD_ID = 'id';
    /** タイトル */
    static FLD_TITLE = 'title';
    /** タイムスタンプ */
    static FLD_TIMESTAMP = 'timestamp';
};
