<template>
    <UxMainPanel>
        <template #content>
            <div class="main-panel width-responsive p-4">
                <GenrePanel v-if="page === 'genre'" :book="book" ref="genrePanel"/>
                <QuestionListPanel v-if="page === 'question-list'" :book="book" :genre="genre" :start="start" ref="questionListPanel"/>
                <QuestionPanel v-if="page === 'question'" :book="book" :genre="genre" :question="question" ref="questionPanel"/>
                <AnswerPanel v-if="page === 'answer'" :book="book" :genre="genre" :question="question" :answer="answer" ref="answerPanel"/>
                <HistoryPanel v-if="page === 'history'" ref="historyPanel"/>
                <FavoritePanel v-if="page === 'favorite'" ref="favoritePanel"/>
                <HomePanel v-if="!page || page === '' || ![
                    'genre','question-list','question','answer','history','favorite'
                ].includes(page)" ref="homePanel"/>
            </div>
        </template>
    </UxMainPanel>
</template>

<script>
    import UxMainPanel from "@/ux/frame/UxMainPanel";

    import HomePanel from "@/views/contents/HomePanel";
    import GenrePanel from "@/views/contents/GenrePanel";
    import QuestionListPanel from "@/views/contents/QuestionListPanel";
    import QuestionPanel from "@/views/contents/QuestionPanel";
    import AnswerPanel from "@/views/contents/AnswerPanel";
    import HistoryPanel from "@/views/contents/HistoryPanel";
    import FavoritePanel from "@/views/contents/FavoritePanel";

    export default {
        components: {
            UxMainPanel,
            HomePanel,
            GenrePanel,
            QuestionListPanel,
            QuestionPanel,
            AnswerPanel,
            HistoryPanel,
            FavoritePanel
        },
        props: {
            page: {
                type: String,
                default: null
            },
            book: {
                type: String,
                default: null
            },
            genre: {
                type: String,
                default: null
            },
            start: {
                type: Number,
                default: 0
            },
            question: {
                type: String,
                default: null
            },
            answer: {
                type: String,
                default: null
            }
        },
        data() {
            return {
            };
        },
        watch: {
        },
        computed: {
        },
        methods: {
        }
    };
</script>

<style scoped>
.main-panel .width-responsive {
    width: 75%;
}

@media (max-width: 1024px) {
    .main-panel .width-responsive {
        width: 100%;
    }
    .main-panel .width-responsive {
        overflow-y: scroll;
        scrollbar-width: none;
        -ms-overflow-style: none;
    }
    .main-panel::-webkit-scrollbar {
        display: none;
    }
}
</style>