<template>
    <div style="height:1px;width:100%;margin:20px 0px;padding:0px 5px;background-color: gray;"></div>
</template>

<script>
    export default {
        components: {
        },
        props: {
        }
    };
</script>

<style scoped>
</style>
