/**
 * IndexedDB
 */
export default class IndexedDB {

    /** データベースID */
    dbId = null;

    /**
     * コンストラクタ
     * @param {String} dbId データベースID
     */
    constructor(dbId) {
        this.dbId = dbId;
    }

    /**
     * 現在のDBバージョン取得
     * @returns {String} 現在のDBバージョン
     */
    async getCurrentVersion() {
        const me = this;
        return await (new Promise(async (resolve, reject) => {
            const request = indexedDB.open(me.dbId);
            // 成功時処理
            request.onsuccess = (event) => {
                const db = event.target.result;
                resolve(db.version);
            };
            // エラー時処理
            request.onerror = (event) => {
                console.error('Database error:', event.target.errorCode);
                // reject(event.target.error);
                resolve(1);
            };
        }));
    }

    /**
     * 次のDBバージョン取得＆カレントバージョン更新
     * @returns {String} 次のDBバージョン
     */
    async getNextVersionAndUpdate() {
        const me = this;
        return await (new Promise(async (resolve, reject) => {
            try {
                const currentVersion = await me.getCurrentVersion();
                const nextVersion = currentVersion + 1;
                resolve(nextVersion);
            } catch (error) {
                reject(error);
            }
        }));
    }

    /**
     * DB作成
     */
    async createDatabase() {
        const me = this;
        await (new Promise(async (resolve, reject) => {
            const request = indexedDB.open(me.dbId, await me.getCurrentVersion());
            // 成功時処理
            request.onupgradeneeded = (event) => {
            };
            // 成功時処理
            request.onsuccess = (event) => {
                const db = event.target.result;
                // 接続を解除する
                db.close();
                resolve();
            };
            // エラー時処理
            request.onerror = (event) => {
                console.error('Database error:', event.target.errorCode);
                // reject(event.target.error);
                resolve();
            };
        }));
    }

    /**
     * DB削除
     */
    async deleteDatabase() {
        const me = this;
        await (new Promise(async (resolve, reject) => {
            const request = indexedDB.open(me.dbId, await me.getCurrentVersion());
            // 成功時処理
            request.onsuccess = (event) => {
                const db = event.target.result;
                // 接続を解除する
                db.close();
                // DB削除
                const requestDelete = indexedDB.deleteDatabase(me.dbId);
                // 成功時処理
                requestDelete.onsuccess = () => {
                    resolve();
                };
                // エラー時処理
                requestDelete.onerror = (event) => {
                    console.error('Error deleting database:', event.target.errorCode);
                    // reject(event.target.error);
                    resolve();
                };
                // ブロック時処理
                requestDelete.onblocked = () => {
                    console.warn('Database delete request blocked');
                    resolve();
                };
            };
            // エラー時処理
            request.onerror = (event) => {
                console.error('Database error:', event.target.errorCode);
                // reject(event.target.error);
                resolve();
            };
        }));
    }

    /**
     * ストア初期化
     * @param {String} storeId ストア名
     * @param {Object} key 主キー情報（{key: キー名, autoInc: 自動連番フラグ}）
     * @param {Array<Object>} indexes インデックスリスト（[{id: インデックスID, unique: 一意フラグ}]）
     */
    async initializeStore(storeId, key, indexes) {
        const me = this;
        await (new Promise(async (resolve, reject) => {
            const request = indexedDB.open(me.dbId, await me.getCurrentVersion());
            // 成功時処理
            request.onsuccess = (event) => {
                const db = event.target.result;
                if (!db.objectStoreNames.contains(storeId)) {
                    me.createStore(storeId, key, indexes).then(() => {
                        resolve();
                    });
                } else {
                    resolve();
                }
            };
            // エラー時処理
            request.onerror = (event) => {
                console.error('Database error:', event.target.errorCode);
                // reject(event.target.error);
                resolve();
            };
        }));
    }

    /**
     * ストア作成
     * @param {String} storeId ストア名
     * @param {Object} key 主キー情報（{key: キー名, autoInc: 自動連番フラグ}）
     * @param {Array<Object>} indexes インデックスリスト（[{id: インデックスID, unique: 一意フラグ}]）
     */
    async createStore(storeId, key, indexes) {
        const me = this;
        await (new Promise(async (resolve, reject) => {
            const request = indexedDB.open(me.dbId, await me.getNextVersionAndUpdate());
            // 更新時
            request.onupgradeneeded = (event) => {
                const db = event.target.result;
                if (!db.objectStoreNames.contains(storeId)) {
                    const objectStore = db.createObjectStore(storeId, {
                        keyPath: key.key,
                        autoIncrement: key.autoInc || false
                    });
                    for (const index of indexes) {
                        objectStore.createIndex(index.id, index.id, { unique: index.unique || false });
                    }
                }
            };
            // 成功時処理
            request.onsuccess = (event) => {
                resolve();
            };
            // エラー時処理
            request.onerror = (event) => {
                console.error('Database error:', event.target.errorCode);
                // reject(event.target.error);
                resolve();
            };
        }));
    }

    /**
     * ストア削除
     * @param {String} storeId ストア名
     */
    async deleteStore(storeId) {
        const me = this;
        await (new Promise(async (resolve, reject) => {
            const request = indexedDB.open(me.dbId, await me.getNextVersionAndUpdate());
            // 更新時
            request.onupgradeneeded = (event) => {
                const db = event.target.result;
                if (db.objectStoreNames.contains(storeId)) {
                    db.deleteObjectStore(storeId);
                }
            };
            // 成功時処理
            request.onsuccess = (event) => {
                resolve();
            };
            // エラー時処理
            request.onerror = (event) => {
                console.error('Database error:', event.target.errorCode);
                // reject(event.target.error);
                resolve();
            };
        }));
    }

    /**
     * データ取得
     * @param {String} storeId ストア名
     * @param {String} id ID
     * @returns {Object} データ
     */
    async getItem(storeId, id) {
        const me = this;
        return await (new Promise(async (resolve, reject) => {
            const request = indexedDB.open(me.dbId, await me.getCurrentVersion());
            // 成功時処理
            request.onsuccess = (event) => {
                try {
                    const db = event.target.result;
                    const transaction = db.transaction([storeId], 'readonly');
                    const objectStore = transaction.objectStore(storeId);

                    const getRequest = objectStore.get(id);

                    // データ取得成功時処理
                    getRequest.onsuccess = (event) => {
                        const result = event.target.result;
                        if (result) {
                            resolve(result);
                        } else {
                            resolve(null);
                        }
                    };
                    // カーソルエラー時処理
                    getRequest.onerror = () => {
                        console.error('Error get data:', getRequest.error);
                        // reject(getRequest.error);
                        resolve(null);
                    };
                } catch (error) {
                    // reject(error);
                    resolve(null);
                }
            };
            // エラー時処理
            request.onerror = (event) => {
                console.error('Database error:', event.target.errorCode);
                // reject(event.target.error);
                resolve(null);
            };
        }));
    }

    /**
     * データ取得
     * @param {String} storeId ストア名
     * @param {Function} callback 行コールバック関数
     * @returns {Array<Object>} データリスト
     */
    async getItems(storeId, callback) {
        const me = this;
        return await (new Promise(async (resolve, reject) => {
            const request = indexedDB.open(me.dbId, await me.getCurrentVersion());
            // 成功時処理
            request.onsuccess = (event) => {
                try {
                    const db = event.target.result;
                    const transaction = db.transaction([storeId], 'readonly');
                    const objectStore = transaction.objectStore(storeId);

                    const cursorRequest = objectStore.openCursor();

                    // カーソル成功時処理
                    cursorRequest.onsuccess = (event) => {
                        const cursor = event.target.result;
                        if (cursor) {
                            if (!callback(cursor.value)) {
                                cursor.transaction.abort(); // トランザクションを中断
                                return;
                            }
                            cursor.continue(); // 次のレコードへ移動
                        } else {
                            resolve([]);
                        }
                    };
                    // カーソルエラー時処理
                    cursorRequest.onerror = () => {
                        console.error('Error fetching data with cursor:', cursorRequest.error);
                        // reject(cursorRequest.error);
                        resolve([]);
                    };
                } catch (error) {
                    // reject(error);
                    resolve(null);
                }
            };
            // エラー時処理
            request.onerror = (event) => {
                console.error('Database error:', event.target.errorCode);
                // reject(event.target.error);
                resolve([]);
            };
        }));
    }

    /**
     * データ件数取得
     * @param {String} storeId ストア名
     * @returns {Number} データ件数
     */
    async getItemCount(storeId) {
        const me = this;
        return await (new Promise(async (resolve, reject) => {
            const request = indexedDB.open(me.dbId, await me.getCurrentVersion());
            // 成功時処理
            request.onsuccess = (event) => {
                try {
                    const db = event.target.result;
                    const transaction = db.transaction([storeId], 'readonly');
                    const objectStore = transaction.objectStore(storeId);

                    const countRequest = objectStore.count();

                    // 件数取得成功時処理
                    countRequest.onsuccess = (event) => {
                        const count = event.target.result;
                        resolve(count);
                    };
                    // 件数取得エラー時処理
                    countRequest.onerror = () => {
                        console.error('Error get count:', countRequest.error);
                        // reject(countRequest.error);
                        resolve([]);
                    };
                } catch (error) {
                    // reject(error);
                    resolve(null);
                }
            };
            // エラー時処理
            request.onerror = (event) => {
                console.error('Database error:', event.target.errorCode);
                // reject(event.target.error);
                resolve([]);
            };
        }));
    }

    /**
     * データ追加
     * @param {String} storeId ストア名
     * @param {Array<Object>} items データリスト
     */
    async addItems(storeId, items) {
        const me = this;
        await (new Promise(async (resolve, reject) => {
            const request = indexedDB.open(me.dbId, await me.getCurrentVersion());
            // 成功時処理
            request.onsuccess = (event) => {
                try {
                    const db = event.target.result;
                    const transaction = db.transaction([storeId], 'readwrite');
                    const objectStore = transaction.objectStore(storeId);
                    // データ追加
                    items.forEach(item => {
                        const addRequest = objectStore.add(item);
                        addRequest.onsuccess = () => {
                            // console.log('Item added:', item);
                        };
                        addRequest.onerror = () => {
                            console.error('Error adding item:', addRequest.error);
                        };
                    });
                    // トランザクション成功時処理
                    transaction.oncomplete = () => {
                        // console.log('All items added successfully');
                        resolve();
                    };
                    // トランザクションエラー時処理
                    transaction.onerror = (event) => {
                        console.error('Transaction error:', event.target.errorCode);
                        // reject(event.target.error);
                        resolve();
                    };
                } catch (error) {
                    // reject(error);
                    resolve(null);
                }
            };
            // エラー時処理
            request.onerror = (event) => {
                console.error('Database error:', event.target.errorCode);
                // reject(event.target.error);
                resolve();
            };
        }));
    }

    /**
     * データ更新
     * @param {String} storeId ストア名
     * @param {Array<Object>} items データリスト
     */
    async updateItems(storeId, items) {
        const me = this;
        await (new Promise(async (resolve, reject) => {
            const request = indexedDB.open(me.dbId, await me.getCurrentVersion());
            // 成功時処理
            request.onsuccess = (event) => {
                try {
                    const db = event.target.result;
                    const transaction = db.transaction([storeId], 'readwrite');
                    const objectStore = transaction.objectStore(storeId);
                    // データ更新
                    items.forEach(item => {
                        const updateRequest = objectStore.put(item);
                        updateRequest.onsuccess = () => {
                            // console.log('Item updated:', item);
                        };
                        updateRequest.onerror = () => {
                            console.error('Error updating item:', updateRequest.error);
                        };
                    });
                    // トランザクション成功時処理
                    transaction.oncomplete = () => {
                        // console.log('All items updated successfully');
                        resolve();
                    };
                    // トランザクションエラー時処理
                    transaction.onerror = (event) => {
                        console.error('Transaction error:', event.target.errorCode);
                        // reject(event.target.error);
                        resolve();
                    };
                } catch (error) {
                    // reject(error);
                    resolve(null);
                }
            };
            // エラー時処理
            request.onerror = (event) => {
                console.error('Database error:', event.target.errorCode);
                // reject(event.target.error);
                resolve();
            };
        }));
    }

    /**
     * データ削除
     * @param {String} storeId ストア名
     * @param {Array<String>} ids IDリスト
     */
    async deleteItems(storeId, ids) {
        const me = this;
        await (new Promise(async (resolve, reject) => {
            const request = indexedDB.open(me.dbId, await me.getCurrentVersion());
            // 成功時処理
            request.onsuccess = (event) => {
                try {
                    const db = event.target.result;
                    const transaction = db.transaction([storeId], 'readwrite');
                    const objectStore = transaction.objectStore(storeId);
                    // データ削除
                    ids.forEach(id => {
                        const deleteRequest = objectStore.delete(id);
                        deleteRequest.onsuccess = () => {
                            // console.log('Item Deleted:', id);
                        };
                        deleteRequest.onerror = () => {
                            console.error('Error deliting item:', deleteRequest.error);
                        };
                    });
                    // トランザクション成功時処理
                    transaction.oncomplete = () => {
                        // console.log('All items Deleted successfully');
                        resolve();
                    };
                    // トランザクションエラー時処理
                    transaction.onerror = (event) => {
                        console.error('Transaction error:', event.target.errorCode);
                        // reject(event.target.error);
                        resolve();
                    };
                } catch (error) {
                    // reject(error);
                    resolve(null);
                }
            };
            // エラー時処理
            request.onerror = (event) => {
                console.error('Database error:', event.target.errorCode);
                // reject(event.target.error);
                resolve();
            };
        }));
    }

    /**
     * データ全削除
     * @param {String} storeId ストア名
     */
    async truncateItems(storeId) {
        const me = this;
        await (new Promise(async (resolve, reject) => {
            const request = indexedDB.open(me.dbId, await me.getCurrentVersion());
            // 成功時処理
            request.onsuccess = (event) => {
                try {
                    const db = event.target.result;
                    const transaction = db.transaction([storeId], 'readwrite');
                    const objectStore = transaction.objectStore(storeId);
                    // データ全削除
                    const clearRequest  = objectStore.clear();
                    clearRequest .onsuccess = () => {
                        // console.log('Item clear');
                        resolve();
                    };
                    clearRequest .onerror = () => {
                        console.error('Error clear item:', clearRequest.error);
                        // reject(clearRequest.error);
                        resolve();
                    };
                } catch (error) {
                    // reject(error);
                    resolve(null);
                }
            };
            // エラー時処理
            request.onerror = (event) => {
                console.error('Database error:', event.target.errorCode);
                // reject(event.target.error);
                resolve();
            };
        }));
    }
};
