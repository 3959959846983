<template>
    <div class="card">
        <div class="mb-5 pb-5">

            <UxSubjectField :title="genreInfo ? genreInfo.name : ''" :desc="''"/>

            <div class="flex align-items-start justify-content-between overflow-hidden w-full max-h-20rem my-4">
                <img :src="genreInfo ? `/data/question/${genreInfo.code}/${genreInfo.image}` : ''" class="w-full" alt="画像なし">
            </div>

            <div class="card flex justify-content-between my-5">
                <div class="card flex justify-content-start gap-2 py-2 px-2">
                    <Button type="button" label="戻る" icon="pi pi-arrow-left" @click="onBack" severity="secondary" outlined />
                </div>
            </div>

            <UxSeparator class="my-5"/>

            <div v-if="(genreInfo||{}).desc && (genreInfo||{}).desc != ''" class="flex flex-wrap text-left my-5">
                <div class="question-list-desc">
                    <span class="question-list-desc-circle1">せ</span><span class="question-list-desc-circle2">め</span>
                    <p v-html="(genreInfo||{}).desc"></p>
                </div>
            </div>

            <Accordion v-if="profileHtml && profileHtml != ''" :activeIndex="null">
                <AccordionTab header="概略">
                    <div class="flex flex-wrap text-left my-5">
                        <p class="text-lg white-space-normal text-overflow-ellipsis mt-2 mx-2" style="word-break: break-all;"
                            v-html="profileHtml">
                        </p>
                    </div>
                </AccordionTab>
            </Accordion>

            <div class="card flex justify-content-between my-5">
                <div class="card flex justify-content-start gap-2 py-2 px-2">
                    <Button type="button" label="出題開始" icon="pi pi-pencil" @click="onStartQuestion" severity="secondary" outlined />
                </div>
            </div>

            <div class="my-5">
                <div v-for="(questionInfo) of questionInfos" :key="questionInfo.id">
                    <div class="flex-grow-1 flex align-items-center justify-content-start cursor-pointer bg-surface-0 hover:bg-bluegray-100" @click="onClickItem(questionInfo.id)">
                        <div class="question-item w-full">
                            <div class="flex flex-wrap">
                                <p class="text-lg text-overflow-ellipsis mx-3">問. <span v-html="questionInfo.title"></span></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="question-item w-full"></div>
            </div>

            <div class="flex flex-wrap justify-content-center gap-2 py-2">
                <Button type="button" label="前へ" @click="onPrev" severity="secondary" outlined :disabled="start <= 0" />
                <Button type="button" label="次へ" @click="onNext" severity="secondary" outlined />
            </div>
        </div>

        <UxSeparator class="my-5"/>

        <div class="my-5 py-5">
            <UxFooterPanel/>
        </div>
    </div>
</template>

<script>
    import UxSeparator from '@/ux/field/UxSeparator';
    import UxSubjectField from '@/ux/field/UxSubjectField';
    import UxFooterPanel from '@/ux/panel/UxFooterPanel';
    import Accordion from 'primevue/accordion';
    import AccordionTab from 'primevue/accordiontab';
    import Button from 'primevue/button';

    import HtmlUtils from "@/app/util/html-utils.mjs";

    import { marked } from 'marked';

    export default {
        components: {
            UxSeparator,
            UxSubjectField,
            UxFooterPanel,
            Accordion,
            AccordionTab,
            Button
        },
        props: {
            book: {
                type: String,
                default: null
            },
            genre: {
                type: String,
                default: null
            },
            start: {
                type: Number,
                default: 0
            }
        },
        data() {
            return {
                genreInfo: null,
                questionInfos: [],
                profileHtml: null,
                pageRowCount: 10
            };
        },
        mounted() {
            const me = this;
            // ジャンル読込
            this.$Service.QuestionService.getGenre(me.genre).then(function(result) {
                me.genreInfo = result;
                // 概略読込
                fetch(`./data/question/${me.genre}/${me.genre}.md`)
                    .then(response => response.text())
                    .then(data => {
                        me.profileHtml = marked.parse(data);
                        me.profileHtml = me.profileHtml.includes('Cannot GET') ? null : me.profileHtml;
                    });
            });
            // 問題リスト読込
            this.$Service.QuestionService.getQuestionList({
                genres: me.genre ? [me.genre] : null,
                start: me.start,
                limit: me.pageRowCount
            }).then(function(result) {
                me.questionInfos = result;
            });
        },
        unmounted() {
        },
        watch: {
        },
        emits: [],
        methods: {
            /**
             * "戻る"イベント時処理
             */
            onBack() {
                HtmlUtils.movePage(`./?page=genre&book=${this.book}&genre=${this.genre}`, '_self');
            },

            /**
             * "出題開始"イベント時処理
             */
            onStartQuestion() {
                this.onClickItem(this.questionInfos[0].id);
            },

            /**
             * "メニュー選択"イベント時処理
             */
            onClickItem(id) {
                HtmlUtils.movePage(`./?page=question&book=${this.book}&genre=${this.genre}&question=${id}`, '_self');
            },

            /**
             * "前へ"イベント時処理
             */
            onPrev() {
                HtmlUtils.movePage(`./?page=question-list&book=${this.book}&genre=${this.genre}&start=${this.start - this.pageRowCount}`, '_self');
            },

            /**
             * "前へ"イベント時処理
             */
            onNext() {
                HtmlUtils.movePage(`./?page=question-list&book=${this.book}&genre=${this.genre}&start=${this.start + this.pageRowCount}`, '_self');
            }
        }
    };
</script>

<style scoped>
.question-list-desc{
    position: relative;
    background: rgba(255, 229, 178, 0.61);
    margin: 2em 0;
    padding: 25px;
    border-radius: 10px;
}

.question-list-desc-circle1{
    font-size: 16px;
    line-height: 1.5;
    position: absolute;
    margin-top: -37px;
    font-weight: bold;
    color: #fff;
    background-color:#ffa726;
    border-radius: 50%;
    text-align: center;
    width: 24px;
    height: 24px;
}

.question-list-desc-circle1:after{
    content: "つ";
    position: absolute;
    margin-top: .2em;
    font-weight: bold;
    color: #fff;
    background-color:#ffa726;
    border-radius: 50%;
    text-align: center;
    width: 24px;
    height: 24px;
}

.question-list-desc-circle2:before{
    content: "";
    position: absolute;
    top: 100%;
    margin-left: -12px;
    border: 5px solid transparent;
    border-top: 10px solid #ffa726;
    -ms-transform: rotate(-20deg);
    -webkit-transform: rotate(-20deg);
    transform: rotate(-20deg);
}

.question-list-desc-circle2{
    position: absolute;
    font-size: 16px;
    line-height: 1.5;
    margin-top: -37px;
    margin-left: 40px;
    font-weight: bold;
    color: #fff;
    background-color:#ffa726;
    border-radius: 50%;
    text-align: center;
    width: 24px;
    height: 24px;
}

.question-list-desc-circle2:after{
    content: "い";
    position: absolute;
    margin-top: .2em;
    font-weight: bold;
    color: #fff;
    background-color:#ffa726;
    border-radius: 50%;
    text-align: center;
    width: 24px;
    height: 24px;
}

.question-list-desc p{
    margin: 0;
    padding: 10px 0px;
}



.question-item {
    border-left: 8px solid var(--orange-500);
    border-right:  1px solid #ccc;
    border-top:  1px solid #ccc;
    border-bottom: none;
    user-select: none;
}
</style>