<template>
    <UxNavigation :visible="visible" :navigationItems="navigationItems" :expandedKeys="expandedKeys">
        <template #header>
            <div class="card flex justify-content-center gap-2">
                <Button v-tooltip.bottom="'メニュー'" icon="pi pi-bars" @click="onCloseNavigation" severity="secondary" outlined />
                <UxLogo :src="'/assets/image/logo.png'" :height="'40'" class="p-1" />
            </div>
        </template>
    </UxNavigation>

    <UxThemeWindow :visible="themeWindowVisible" v-on:change-visible="onThemeWindowVisible" />
    <UxTermsWindow :visible="termsWindowVisible" v-on:change-visible="onTermsWindowVisible" />
    <UxProfileWindow :visible="profileWindowVisible" v-on:change-visible="onProfileWindowVisible" />
    <UxContactWindow :visible="contactWindowVisible" v-on:change-visible="onContactWindowVisible" />
    <UxApplicationWindow :visible="applicationWindowVisible" v-on:change-visible="onApplicationWindowVisible" />
</template>

<script>
    import Button from "primevue/button"

    import UxNavigation from "@/ux/frame/UxNavigation"
    import UxLogo from "@/ux/field/UxLogo"

    import UxThemeWindow from "@/ux/window/UxThemeWindow";
    import UxTermsWindow from "@/ux/window/UxTermsWindow";
    import UxProfileWindow from "@/ux/window/UxProfileWindow";
    import UxContactWindow from "@/ux/window/UxContactWindow";
    import UxApplicationWindow from "@/ux/window/UxApplicationWindow";

    export default {
        components: {
            UxNavigation,
            Button,
            UxLogo,
            UxThemeWindow,
            UxTermsWindow,
            UxProfileWindow,
            UxContactWindow,
            UxApplicationWindow
        },
        props: {
            visible: {
                type: Boolean,
                default: false
            }
        },
        emits: ['close', 'select-item'],
        data() {
            return {
                themeWindowVisible: false,
                termsWindowVisible: false,
                profileWindowVisible: false,
                contactWindowVisible: false,
                applicationWindowVisible: false,
                expandedKeys: {
                    '0': true,
                    '1': true,
                    '2': true,
                    '3': true
                },
                navigationItems: [
                    // {
                    //     key: '1',
                    //     label: '設定',
                    //     icon: 'pi pi-fw pi-cog',
                    //     items: [
                    //         {
                    //             key: '1_1',
                    //             label: 'テーマ',
                    //             icon: 'pi pi-fw pi-wrench',
                    //             command: () => { this.themeWindowVisible = true; }
                    //         }
                    //     ]
                    // },
                    // {
                    //     key: '2',
                    //     label: 'SNS',
                    //     icon: 'pi pi-fw pi-comments',
                    //     items: [
                    //         {
                    //             key: '2_0',
                    //             label: 'Twitter',
                    //             icon: 'pi pi-fw pi-twitter',
                    //             command: () => { this['$emit']('select-item', 'twitter'); }
                    //         },
                    //         {
                    //             key: '2_1',
                    //             label: 'Facebook',
                    //             icon: 'pi pi-fw pi-facebook',
                    //             command: () => { this['$emit']('select-item', 'facebook'); }
                    //         }
                    //     ]
                    // },
                    {
                        key: '3',
                        label: 'About',
                        icon: 'pi pi-fw pi-question',
                        items: [
                            {
                                key: '3_0',
                                label: '当サイトについて',
                                icon: 'pi pi-fw pi-info-circle',
                                command: () => { this.applicationWindowVisible = true; }
                            },
                            // {
                            //     key: '3_1',
                            //     label: 'ヘルプ',
                            //     icon: 'pi pi-fw pi-question',
                            //     command: () => {
                            //         HtmlUtils.movePage('/help', '_blank');
                            //     }
                            // },
                            {
                                key: '3_2',
                                label: '利用規約',
                                icon: 'pi pi-fw pi-file',
                                command: () => { this.termsWindowVisible = true; }
                            },
                            {
                                key: '3_3',
                                label: 'お問合せ',
                                icon: 'pi pi-fw pi-envelope',
                                command: () => { this.contactWindowVisible = true; }
                            },
                            // {
                            //     key: '3_4',
                            //     label: 'ご支援',
                            //     icon: 'pi pi-fw pi-users',
                            //     command: () => {
                            //         HtmlUtils.movePage('/donation', '_blank');
                            //     }
                            // },
                            {
                                key: '3_5',
                                label: '管理人情報',
                                icon: 'pi pi-fw pi-user',
                                command: () => { this.profileWindowVisible = true; }
                            }
                        ]
                    }
                ]
            };
        },
        computed: {
        },
        methods: {
            /**
             * "ナビゲーション閉じる"イベント時処理
             */
            onCloseNavigation() {
                this['$emit']('close');
            },

            /**
             * "テーマウィンドウ表示切替"イベント時処理
             */
            onThemeWindowVisible(visible) {
                this.themeWindowVisible = visible;
            },

            /**
             * "利用規約ウィンドウ表示切替"イベント時処理
             */
            onTermsWindowVisible(visible) {
                this.termsWindowVisible = visible;
            },

            /**
             * "プロフィールウィンドウ表示切替"イベント時処理
             */
            onProfileWindowVisible(visible) {
                this.profileWindowVisible = visible;
            },

            /**
             * "お問合せウィンドウ表示切替"イベント時処理
             */
            onContactWindowVisible(visible) {
                this.contactWindowVisible = visible;
            },

            /**
             * "アプリケーションウィンドウ表示切替"イベント時処理
             */
            onApplicationWindowVisible(visible) {
                this.applicationWindowVisible = visible;
            }
        }
    };
</script>

<style scoped>
</style>