<template>
  <router-view />
</template>

<script>
export default {
  name: "App",
  components: {},
  mounted() {
    // this.$router.push({ name: 'main' });
    // this.$router.push({ name: 'about' });
  },
};
</script>

<style>
/**
 * 共通スタイル
 */
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 0;
  padding: 0;
}
</style>
