import { createApp } from 'vue';
import { Vue3Mq } from "vue3-mq";

import App from './App.vue'
import Router from './router';
import PrimeVue from "primevue/config";

// 日本語設定
import ja from "@/i18n/locale/ja";

// テーマ
import "primevue/resources/themes/saga-blue/theme.css";
import "primevue/resources/themes/vela-green/theme.css";

// スタイル
import "primevue/resources/primevue.min.css"; // ベース
import "primeicons/primeicons.css"; // アイコン
import "primeflex/primeflex.css"; // primeflex

// スタイル
import "@/assets/css/base.css"; // ベース
import "@/assets/css/markdown.css"; // ベース

// アプリケーション
import AppService from "@/app/service/AppService.mjs";
import QuestionService from "@/app/service/QuestionService.mjs";
import HistoryService from "@/app/service/HistoryService.mjs";
import FavoriteService from "@/app/service/FavoriteService.mjs";

// スタイル
const app = createApp(App);

// ライブラリ登録
app.use(Router);
app.use(PrimeVue, { locale: ja });
app.use(Vue3Mq, { preset: "vuetify" });

// ツールチップ有効化
import Tooltip from 'primevue/tooltip';
app.directive('tooltip', Tooltip);

// 設定ファイル読込
import Settings from "@/assets/Settings.json";

// グローバル変数を定義
app.config.globalProperties.$Settings = Settings;
app.config.globalProperties.$Service = {
    AppService: new AppService(),
    QuestionService: new QuestionService(),
    HistoryService: new HistoryService(),
    FavoriteService: new FavoriteService()
};
for (const key in app.config.globalProperties.$Service) {
    app.config.globalProperties.$Service[key].SERVICES = app.config.globalProperties.$Service;
}
const initializeTasks = [];
for (const key in app.config.globalProperties.$Service) {
    if (app.config.globalProperties.$Service[key].initialize) {
        initializeTasks.push(app.config.globalProperties.$Service[key].initialize());
    }
}
// Promise.all(initializeTasks)
// .then(function(messege){
//     console.log(messege);
//     // マウント
//     app.mount('#app');
// })
// .catch(function(reason) {
//     console.log(reason);
// });
// マウント
app.mount('#app');
