<template>
    <div class="card">
        <div class="mb-5 pb-5">
            <UxSubjectField :title="'問題'" :desc="''"/>

            <div class="card flex justify-content-between py-5">
                <div class="card flex justify-content-start gap-2 py-2 px-2">
                    <Button type="button" label="問題一覧へ" icon="pi pi-list" @click="onQuestionList" severity="secondary" outlined />
                </div>
            </div>

            <UxSeparator class="my-1"/>

            <div class="card flex justify-content-between py-5">
                <div class="card flex justify-content-start gap-2 py-2 px-2">
                </div>
                <div class="card flex justify-content-end gap-2 py-2 px-2">
                    <Button type="button" label="付箋" :icon="favoriteInfo ? 'pi pi-star-fill' : 'pi pi-star'" @click="onFavorite" severity="secondary" outlined />
                </div>
            </div>

            <div class="my-2">
                <div class="flex-grow-1 flex align-items-center justify-content-start border-500 border-1">
                    <div class="card w-full my-5 mx-2">
                        <div class="question-box mb-5">
                            <div class="flex flex-wrap text-left mb-5">
                                <p class="text-lg white-space-normal text-overflow-ellipsis mt-2 mx-5" style="word-break: break-all;"
                                    v-html="questionInfo ? questionInfo.question : ''">
                                </p>
                            </div>
                            <div class="question-box-chalk1"></div>
                            <div class="question-box-chalk2"></div>
                        </div>
                        <div class="text-left">
                            <div v-for="(selectionItem, index) of questions" :key="selectionItem">
                                <p class="flex flex-wrap text-lg white-space-normal text-overflow-ellipsis mt-2 mx-5" style="word-break: break-all;">
                                    {{'ABCDEFGHIJKLMNOPQRSTUVWXYZ'[index] + '. ' + selectionItem}}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="flex flex-wrap justify-content-center gap-2 py-5">
                <div v-for="(selectionItem, index) of questions" :key="selectionItem">
                    <Button type="button" :label="'ABCDEFGHIJKLMNOPQRSTUVWXYZ'[index]" @click="onAnswer(selectionItem)" severity="secondary" outlined />
                </div>
                <div class="mx-5">
                    <Button type="button" label="答えを見る" icon="pi pi-arrow-right" @click="onShowAnswer" severity="secondary" outlined />
                </div>
            </div>

            <div class="flex flex-wrap justify-content-center gap-2 py-2">
                <Button type="button" label="前へ" @click="onPrev" severity="secondary" outlined :disabled="prevQuestionId == null" />
                <Button type="button" label="次へ" @click="onNext" severity="secondary" outlined :disabled="nextQuestionId == null" />
            </div>
            <div class="flex flex-wrap justify-content-center gap-2 py-2">
                <Button type="button" label="ランダムで出題" @click="onRandom" severity="secondary" outlined />
            </div>
        </div>

        <UxSeparator class="my-5"/>

        <div class="my-5 py-5">
            <UxFooterPanel/>
        </div>
    </div>
</template>

<script>
    import UxSeparator from '@/ux/field/UxSeparator';
    import UxSubjectField from '@/ux/field/UxSubjectField';
    import UxFooterPanel from '@/ux/panel/UxFooterPanel';
    import Button from 'primevue/button';

    import HtmlUtils from "@/app/util/html-utils.mjs";
    import HistoryStore from "@/app/entity/HistoryStore.mjs";
    import FavoriteStore from "@/app/entity/FavoriteStore.mjs";

    export default {
        components: {
            UxSeparator,
            UxSubjectField,
            UxFooterPanel,
            Button
        },
        props: {
            book: {
                type: String,
                default: null
            },
            genre: {
                type: String,
                default: null
            },
            question: {
                type: String,
                default: null
            }
        },
        data() {
            return {
                questionInfo: null,
                prevQuestionId: null,
                nextQuestionId: null,
                questions: null,
                favoriteInfo: null
            };
        },
        mounted() {
            const me = this;
            // 問題読込
            this.$Service.QuestionService.getQuestion(this.genre, this.question).then(function(result) {
                me.questionInfo = result;
                // 問題の順番をシャッフル
                const questions = me.questionInfo.selection;
                for (let i = questions.length - 1; i > 0; i--) {
                    const j = Math.floor(Math.random() * (i + 1));
                    [questions[i], questions[j]] = [questions[j], questions[i]];
                }
                if (!questions.slice(0, 4).includes(result.answer)) {
                    const min = Math.ceil(0);
                    const max = Math.floor(4 - 1);
                    const randomNum = Math.floor(Math.random() * (max - min + 1)) + min;
                    questions.splice(randomNum, 0, result.answer);
                }
                me.questions = questions.slice(0, 4);
            });
            // 前の問題取得
            this.$Service.QuestionService.getPrevQuestion(this.genre, this.question).then(function(result) {
                me.prevQuestionId = result ? result.id : null;
            });
            // 次の問題取得
            this.$Service.QuestionService.getNextQuestion(this.genre, this.question).then(function(result) {
                me.nextQuestionId = result ? result.id : null;
            });
            // お気に入り取得
            this.$Service.FavoriteService.getFavorite(this.question).then(function(result) {
                me.favoriteInfo = result;
            });
        },
        unmounted() {
        },
        watch: {
        },
        emits: [],
        methods: {
            /**
             * "お気に入り登録or解除"イベント時処理
             */
            onFavorite() {
                const me = this;
                if (me.favoriteInfo) {
                    // 解除
                    me.$Service.FavoriteService.deleteFavorite(me.questionInfo.id).then(function() {
                        me.favoriteInfo = null;
                    });
                } else {
                    // 登録
                    const favorite = {};
                    favorite[FavoriteStore.FLD_BOOK] = me.book;
                    favorite[FavoriteStore.FLD_GENRE] = me.genre;
                    favorite[FavoriteStore.FLD_ID] = me.questionInfo.id;
                    favorite[FavoriteStore.FLD_TITLE] = me.questionInfo.title;
                    favorite[FavoriteStore.FLD_TIMESTAMP] = (new Date()).getTime();
                    me.$Service.FavoriteService.addFavorite(favorite).then(function() {
                        me.favoriteInfo = favorite;
                    });
                }
            },

            /**
             * "問題一覧"イベント時処理
             */
            onQuestionList() {
                HtmlUtils.movePage(`./?page=question-list&book=${this.book}&genre=${this.genre}`, '_self');
            },

            /**
             * "解答"イベント時処理
             */
            onAnswer(answer) {
                const me = this;
                const history = {};
                history[HistoryStore.FLD_BOOK] = me.book;
                history[HistoryStore.FLD_GENRE] = me.genre;
                history[HistoryStore.FLD_ID] = me.questionInfo.id;
                history[HistoryStore.FLD_TITLE] = me.questionInfo.title;
                history[HistoryStore.FLD_TIMESTAMP] = (new Date()).getTime();
                history[HistoryStore.FLD_CORRECT] = answer == me.questionInfo.answer;
                me.$Service.HistoryService.addHistory(history).then(function() {
                    // HtmlUtils.movePage(`./?page=answer&book=${me.book}&genre=${me.genre}&question=${me.questionInfo.id}&answer=${answer}`, '_self');
                });
                HtmlUtils.movePage(`./?page=answer&book=${this.book}&genre=${this.genre}&question=${this.questionInfo.id}&answer=${answer}`, '_self');
            },

            /**
             * "解答を見る"イベント時処理
             */
            onShowAnswer() {
                HtmlUtils.movePage(`./?page=answer&book=${this.book}&genre=${this.genre}&question=${this.questionInfo.id}&answer=${this.questionInfo.answer}`, '_self');
            },

            /**
             * "前へ"イベント時処理
             */
            onPrev() {
                if (this.prevQuestionId) {
                    HtmlUtils.movePage(`./?page=question&book=${this.book}&genre=${this.genre}&question=${this.prevQuestionId}`, '_self');
                }
            },

            /**
             * "前へ"イベント時処理
             */
            onNext() {
                if (this.nextQuestionId) {
                    HtmlUtils.movePage(`./?page=question&book=${this.book}&genre=${this.genre}&question=${this.nextQuestionId}`, '_self');
                }
            },

            /**
             * "ランダム"イベント時処理
             */
            onRandom() {
                const me = this;
                const randomFunc = function() {
                    me.$Service.QuestionService.getRandomQuestion({
                        books: [me.book],
                        genres: [me.genre]
                    }).then(function(result) {
                        if (result.id == me.questionInfo.id) {
                            randomFunc();
                            return;
                        }
                        HtmlUtils.movePage(`./?page=question&book=${me.book}&genre=${me.genre}&question=${result.id}`, '_self');
                    });
                };
                randomFunc();
            }
        }
    };
</script>

<style scoped>
.question-box{
    background: #104300;
    margin: 1em 0;
    padding: 1em 1em 0 1em;
    border: 8px solid #a60;
    box-shadow: 2px 2px 4px #999, 2px 2px 2px #020 inset;
}

.question-box p{
    margin: 0;
    padding: 0;
    color: #fff;
    text-shadow: 0px 0px 2px #fff;
}

.question-box-chalk1{
    display: block;
    margin-top: 10px;
    margin-left: 90%;
    border: solid 3px #fff;
    width: 15px;
    height: 6px;
    border-radius: 3px 2px 0 2px;
}

.question-box-chalk2{
    display: block;
    margin-top: -6px;
    margin-left: calc(90% - 30px);
    border: solid 3px #ffee58;
    width: 20px;
    height: 6px;
    border-radius: 3px 2px 0 2px;
}
</style>