/**
 * 履歴ストア
 */
export default class HistoryStore {

    /** ストア名 */
    static STORE_NAME = 'question_history';

    /**
     * 属性
     */
    /** ブック */
    static FLD_BOOK = 'book';
    /** ジャンル */
    static FLD_GENRE = 'genre';
    /** ID */
    static FLD_ID = 'id';
    /** タイトル */
    static FLD_TITLE = 'title';
    /** タイムスタンプ */
    static FLD_TIMESTAMP = 'timestamp';
    /** 正解判定 */
    static FLD_CORRECT = 'correct';
};
