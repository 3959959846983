/**
 * HTMLユーティリティ
 */
export default class HtmlUtils {

    /**
     * ページ移動
     * @param {String} url URL
     * @param {String} target 対象
     */
    static movePage(url, target='_blank', rel='nofollow noopener noreferrer') {
        const a = document.createElement("a");
        document.body.appendChild(a);
        a.href = url;
        a.target = target;
        a.rel = rel;
        a.click();
        a.remove();
        URL.revokeObjectURL(url);
    }

    /**
     * ダウンロード
     * @param {String} url URL
     * @param {String} filename ファイル名
     */
    static download(url, filename) {
        const a = document.createElement("a");
        document.body.appendChild(a);
        a.href = url;
        a.download = filename;
        a.click();
        a.remove();
        URL.revokeObjectURL(url);
    }

    /**
     * リクエストパラメータ取得
     * @param {Stirng} param キー
     * @returns {String} パラメータ
     */
    static getRequestParameter(param) {
        // URLを取得
        let url = new URL(window.location.href);

        // URLSearchParamsオブジェクトを取得
        let params = url.searchParams;

        // getメソッド
        return params.get(param);
    }

    /**
     * リクエストパラメータ取得
     * @param {Stirng} param キー
     * @returns {String} パラメータ
     */
    static setRequestParameter(param, value) {
        // URLを取得
        let url = new URL(window.location.href);

        // URLSearchParamsオブジェクトを取得
        let params = url.searchParams;

        // パラメータ設定
        let findParam = null;
        for (const [k, v] of params) {
            if (k === param) {
                findParam = k;
                break;
            }
        }
        if (value) {
            params.set(param, value);
        } else {
            if (findParam) {
                params.delete(param);
            }
        }

        // URL変更
		history.replaceState(null, '', url.href);
    }

    /**
     * リクエストインデックス取得
	 * @returns {String} パラメータ
     */
    static getRequestIndex() {
        // URLを取得
        const url = window.location.href;
        const last = url.lastIndexOf('#');
        if (last < 0) {
            null;
        }

        // 「#」を除いて取得
        const hash = url.slice(last + 1);
        if (url.startsWith(hash)) {
            return null;
        }

        return hash;
    }
};
