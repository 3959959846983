export default {
    "startsWith":"始まる",
    "contains":"含む",
    "notContains":"含まない",
    "endsWith":"終わる",
    "equals":"等しい",
    "notEquals":"等しくない",
    "noFilter":"フィルターなし",
    "filter":"フィルター",
    "lt":"未満",
    "lte":"以下",
    "gt":"超える",
    "gte":"以上",
    "dateIs":"等しい",
    "dateIsNot":"等しくない",
    "dateBefore":"指定日より過去",
    "dateAfter":"指定日より未来",
    "custom":"カスタム",
    "clear":"クリア",
    "apply":"適用",
    "matchAll":"全て一致",
    "matchAny":"いずれかが一致",
    "addRule":"条件追加",
    "removeRule":"条件削除",
    "accept": "はい",
    "reject": "いいえ",
    "choose":"選択",
    "upload":"アップロード",
    "cancel":"キャンセル",
    "close":"閉じる",
    "dayNames": ["日曜日", "月曜日", "火曜日", "水曜日", "木曜日", "金曜日", "土曜日"],
    "dayNamesShort": ["日", "月", "火", "水", "木", "金", "土"],
    "dayNamesMin": ["日", "月", "火", "水", "木", "金", "土"],
    "monthNames": ["1月", "2月", "3月", "4月", "5月", "6月", "7月", "8月", "9月", "10月", "11月", "12月"],
    "monthNamesShort": ["1月", "2月", "3月", "4月", "5月", "6月", "7月", "8月", "9月", "10月", "11月", "12月"],
    "today":"今日",
    "weekHeader":"週",
    "firstDayOfWeek":0,
    "dateFormat":"yy/mm/dd",
    "weak":"弱い",
    "medium":"普通",
    "strong":"強い",
    "passwordPrompt":"パスワードを入力",
    "emptyFilterMessage":"オプションなし",
    "emptyMessage":"結果なし",
    "aria": {
        "trueLabel": "True",
        "falseLabel": "False",
        "nullLabel": "未選択",
        "pageLabel": "ページ",
        "firstPageLabel": "最初のページ",
        "lastPageLabel": "最後のページ",
        "nextPageLabel": "次のページ",
        "previousPageLabel": "前のページ",
        "selectLabel": "選択",
        "unselectLabel": "選択解除",
        "expandLabel": "展開する",
        "collapseLabel": "閉じる"
    }
}