<template>
    <Dialog modal v-bind:visible="visible" header="テーマ" :style="{ width: '30vw' }" :breakpoints="{ '960px': '50vw', '641px': '100vw' }"
        v-on:update:visible="onVisible" :draggable="draggable" >
        <DataTable v-model:selection="selectedData" :value="data" dataKey="code"
            @rowSelect="onRowSelect" @rowUnselect="onRowUnselect" tableStyle="">
            <Column selectionMode="single" headerStyle="width: 3rem"></Column>
            <Column header="" headerStyle="width: 3rem">
                <template #body="slotProps">
                    <div :style="`width: 24px; height: 24px; border: 2px double #000000; background:${slotProps.data.color};`"></div>
                </template>
            </Column>
            <Column field="name" header=""></Column>
        </DataTable>
    </Dialog>
</template>

<script>
    import Dialog from 'primevue/dialog';
    import DataTable from 'primevue/datatable';
    import Column from 'primevue/column';

    export default {
        components: {
            Dialog,
            DataTable,
            Column
        },
        props: {
            visible: {
                type: Boolean,
                default: false
            },
            draggable: {
                type: Boolean,
                default: false
            }
        },
        data() {
            const themeLink = document.getElementById('theme-link');
            const datas = this.$Service.AppService.getThemeDatas();
            let selectedData = null;
            for (const data of datas) {
                if (themeLink.href.includes(`/${data.code}/`)) {
                    selectedData = data;
                    break;
                }
            }
            return {
                data: datas,
                selectedData: selectedData
            }
        },
        emits: ['change-visible'],
        methods: {
            /**
             * "表示切替"イベント時処理
             */
            onVisible(visible) {
                this['$emit']('change-visible', visible);
            },

            /**
             * 行選択時処理
             */
            onRowSelect(event) {
                document.documentElement.classList.toggle(event.data.code);
                this.$Service.AppService.changeTheme(event.data.code);
            },

            /**
             * 行選択解除時処理
             */
            onRowUnselect(event) {
                document.documentElement.classList.toggle(event.data.code);
            }
        }
    };
</script>

<style scoped>
</style>