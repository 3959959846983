<template>
    <main>
        <div class="main-panel card flex justify-content-center">
            <div class="main-content flex justify-content-center w-full">
                <slot name="content"></slot>
            </div>
        </div>
    </main>
</template>

<script>
    export default {
        components: {
        }
    };
</script>

<style scoped>
    .main-panel {
        width: 100%;
        height: calc(100vh - 60px);
        margin-top: 60px;
        box-sizing: border-box;
        background-color: #fff;
    }
    .main-content {
        background-color: #fff;
    }
</style>
