<template>
    <div>
        <div class="flex flex-wrap align-items-center justify-content-start h-4rem bg-orange-500 text-white text-3xl font-bold border-round my-2 px-4 gap-2">
            <img :src="'/assets/image/subject_48.png'" alt="画像なし">{{title}}
        </div>
        <div v-if="desc" class="flex flex-wrap justify-content-start">
            <p class="px-2 text-secondary">{{desc}}</p>
        </div>
    </div>
</template>

<script>
    export default {
        components: {
        },
        props: {
            title: {
                type: String,
                default: ""
            },
            desc: {
                type: String,
                default: ""
            }
        }
    };
</script>

<style scoped>
</style>
